import Image from 'next/image';
import React from 'react';

import ROUTES from '../routes';

import Button from '@components/Button';
import CustomHead from '@components/CustomHead';
import Footer from '@components/FooterNew';
import Header from '@components/HeaderNew';
import Heading from '@components/Heading';
import Section from '@components/Section';

const downloadOptions = [
  {
    id: 'windows',
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/images/windows.svg`,
    buttons: [
      {
        text: 'Download',
        url: ROUTES.getPopSQL('exe'),
      },
    ],
  },
  {
    id: 'mac',
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/images/mac.svg`,
    buttons: [
      {
        text: 'Download for Intel',
        url: ROUTES.getPopSQL('dmg'),
      },
      {
        text: 'Download for Apple Silicon',
        url: ROUTES.getPopSQL('dmg_arm64'),
      },
    ],
  },
  {
    id: 'linux',
    image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/images/linux.svg`,
    buttons: [
      {
        text: 'Download',
        url: ROUTES.getPopSQL('AppImage'),
      },
    ],
  },
];

const DownloadPage = () => {
  return (
    <>
      <CustomHead
        meta={{
          title: 'Start using PopSQL | PopSQL',
        }}
      />
      <main
        style={{
          background: `url(${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/home_hero_bg.svg)`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Header />

        <Section spacing="mt-12 sm:mt-20 px-4 gap-4">
          <div className="text-center">
            <Image
              alt="popsql icon"
              src={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/popsql_app_icon.png`}
              height="115"
              width="100"
            />
          </div>

          <Heading Tag="h1" size="xlg" className="text-center md:mb-8 -mt-6">
            PopSQL for desktop
          </Heading>

          <p className="mb-8 font-medium font-inter text-lg md:text-2xl text-center max-w-4xl">
            Download the PopSQL desktop app for a native experience,
            notifications and connecting directly from your computer to your
            database.
          </p>

          <ul className="grid lg:grid-cols-3 gap-4 md:gap-6 mb-24">
            {downloadOptions.map((option) => (
              <li
                key={option.id}
                className="bg-white rounded-4xl px-8 py-8 md:py-12"
                style={{
                  boxShadow:
                    '0px 4px 80px 0px rgba(0, 0, 0, 0.08), 0px 0px 0px 2px rgba(0, 0, 0, 0.08)',
                }}
              >
                <Image
                  alt={`${option.id} logo`}
                  src={option.image}
                  height="114"
                  width="282"
                />

                <div className="mt-4 md:mt-8">
                  {option.buttons.map((button, index) => (
                    <Button key={index} href={button.url} className="mt-2 h-12">
                      {button.text}
                    </Button>
                  ))}
                </div>
              </li>
            ))}
          </ul>
        </Section>
      </main>
      <Footer />
    </>
  );
};

export default DownloadPage;
